import Title from '../components/Title'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Impressum = () => {
  const { t } = useTranslation()
  return (
    <div className='container mx-auto mt-24'>
      <Title text='Impressum' />
      <div>{t("impressum.responsible")}: Florian Rudin, Kienbergweg 15, 4450 Sissach</div>
      <Link to="/" className='underline hover:no-underline mt-4'>{t("backToStart")}</Link>
    </div>
  )
}

export default Impressum