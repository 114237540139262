type Props = {
  text: string,
  className?: React.HTMLAttributes<HTMLHeadingElement>['className']
  type?: "h1" | "h2" | "h3"
}

const Title = (props: Props) => {
  return (
    <div className={`font-caviar ${props.className ? props.className : ""}`}>
      {
        // return element based on type
        props.type === "h1" ?
          <h1 className={`text-2xl border-b-2 border-black inline-block ${props.className ? props.className : ""}`}>{props.text}</h1>
          : props.type === "h2" ?
            <h2 className={`text-xl border-b-2 border-black inline-block ${props.className ? props.className : ""}`}>{props.text}</h2>
            :
            <h3 className={`text-lg border-b-2 border-black inline-block ${props.className ? props.className : ""}`}>{props.text}</h3>
      }
    </div>
  )
}

export default Title