import { PropsWithChildren, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const NavItem = (props: PropsWithChildren<{ 
  to: string, 
  onClick: React.MouseEventHandler,
className?: string, }>) => (
  <Link onClick={props.onClick} to={props.to} className={`basis-1/5 flex items-center px-6 hover:text-amber-400 hover:bg-stone-700 transition duration-500
    font-extralight ${props.className}`}>
    {props.children}
  </Link>
)

type NavProps = {
  showNav: boolean
}



const Nav = (props: NavProps) => {
  const { t } = useTranslation()

  const [isMobile, setIsMobile] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);

  const handleShowMobileNav = () => {
    setShowMobileNav(!showMobileNav);

  }
  const handleScrollTo = (id: string) => () => {
    const element = document.getElementById(id)
    element?.scrollIntoView({ behavior: "smooth" })
  
    setShowMobileNav(false);
  }


  useEffect(() => {
    const onScroll = () => {
      setIsMobile(window.innerWidth < 768);
    };

    onScroll();

    window.addEventListener('resize', onScroll, { passive: true });

    return () => {
      window.removeEventListener('resize', onScroll)
    };
  });

  return (
    isMobile ?
    <div className={`${props.showNav && isMobile ? "translate-y-0" : "-translate-y-full"} 
    transition duration-500 h-16 w-full 
    bg-stone-800 fixed z-[9999]`}>
      {
        /* Mobile Nav, three lines button. when click nav appears. */
        <div className="flex text-white justify-end">
          <button onClick={handleShowMobileNav} className="p-4 font-lexend text-xl">
            MENU
          </button>

          <div id="mobile-nav" className={`
            w-full
            h-screen
            absolute
            flex flex-col
            items-stretch
            bg-neutral-500  text-3xl font-lexend
            text-center
            transition duration-500
            ${showMobileNav ? "translate-y-0" : "-translate-y-full"}
             `}>

            {
              /* close button */
              <button onClick={handleShowMobileNav} className="px-8 py-6 font-lexend text-3xl absolute right-0">
                X
              </button>
            }

            <NavItem to="#" className="bg-stone-500 justify-center" onClick={handleScrollTo("home")}>{ t("nav.home") }</NavItem>
            <NavItem to="#" className="bg-stone-600 justify-center" onClick={handleScrollTo("about")}>{ t("nav.about") }</NavItem>
            <NavItem to="#" className="bg-stone-700 justify-center" onClick={handleScrollTo("referenzen")}>{ t("nav.references") }</NavItem>
            <NavItem to="#" className="bg-stone-900 justify-center" onClick={handleScrollTo("kontakt")}>{ t("nav.contact") }</NavItem>
            </div>
        </div>
      }
    </div>
    : 

    <div className={`${props.showNav ? "translate-y-0" : "-translate-y-full"} transition duration-500 fixed text-white z-20 text-lg uppercase bg-stone-800 w-full`}>
        <nav className="flex flex-row justify-around items-start font-lexend">
          <NavItem to="#" className={"py-4 justify-center"} onClick={handleScrollTo("home")}>{ t("nav.home") }</NavItem>
          <NavItem to="#" className={"py-4 justify-center"} onClick={handleScrollTo("about")}>{ t("nav.about") }</NavItem>
          <NavItem to="#" className={"py-4 justify-center"} onClick={handleScrollTo("referenzen")}>{ t("nav.references") }</NavItem>
          <NavItem to="#" className={"py-4 justify-center"} onClick={handleScrollTo("kontakt")}>{ t("nav.contact") }</NavItem>
        </nav>
      </div>
    
  )

}

export default Nav