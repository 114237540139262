import { PropsWithChildren } from "react"
import Paragraph from "./Paragraph"

type AboutPropertyTextProps = PropsWithChildren<{
  Icon: React.FunctionComponent<{ alt: string, className: string }>,
  iconAlt: string,
  title: string,
  description: string,
}>

const AboutPropertyText = (props: AboutPropertyTextProps) => (
  <div className="flex items-center">
    <div className="">
      <props.Icon alt={props.iconAlt} className="w-8 h-8 text-amber-400" />
    </div>
    <div className="flex flex-col my-4">
      <h2 className="px-4 text-amber-400 hyphens-auto  text-xl font-bold uppercase font-lexend" lang="de">{props.title}</h2>
      
      <Paragraph>
        {props.description}
      </Paragraph>
    </div>
  </div>
)

export default AboutPropertyText