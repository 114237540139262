import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18next
.use(initReactI18next)
.use(LanguageDetector)
.init({
  fallbackLng: 'de',
  resources: {
    en: {
      translation: {
        "hello": "Hello",
        "iam": "I am Ming",
        nav: {
          home: "Home",
          about: "About",
          references: "References",
          prices: "Prices",
          contact: "Contact",
        },
        intro: {
          "did-you-always-want": "Did you always want to",
          "learn-chinese": "learn Chinese?",
          "third-paragraph": "Or do you want to improve your language skills or learn Business Chinese or even Calligraphy?",
          "fourth-paragraph": 'then you are exactly <1 className="whitespace-nowrap">at the right place!</1>'
        },
        about :{
          place: {
            title: "Teaching place",
            value: "Online"
          },
          languages: {
            title: "Languages",
            value: "Chinese, English, German"
          },
          text1: "As a passionate Chinese teacher with over 10 years of experience, I offer customized online Chinese lessons for all levels and age groups.",
          text2: 'Und this at <1>best rates</1>!',
          center: "You are the center of our class!",
          text3:    `Your needs and wishes should shape the lessons. I will help you create a customized lesson plan according to your goals and ideas.

My aim is to create a dynamic and interactive learning environment that makes learning lively, interesting, and efficient.
            `,
        },
        students: {
          title: "What do my students say?",
        },
        onlineMaterial: {
          text1: "Follow my Youtube channel and profit from my free online materials."
        },
        defaultMessage: "I'd like a quote for personalized Chinese lessons.",
        footer: {
          lookingForward: "I'm looking forward to your message",
          yourName: "Your name",
          pleaseEnterYourName: "Please enter your name!",
          yourEmail: "Your email address",
          pleaseEnterYourEmail: "Please enter your email address",
          yourMessage: "Your message",
          pleaseEnterYourMessage: "Please enter a message",
        },
        message: {
          success: "Thank you! Your message was sent successfully.",
        },
        contact: {
          send: "Send",
          direct: "Or directly contact me by <1>email</1>.",
        },
        privacy: {
          title: "Privacy",
          content: "We generally don't store any data. The only data that is stored on our mailserver, is the data you manually enter into the contact form."
        },
        impressum: {
          title: "Impressum",
          responsible: "Responsible",
        },
        backToStart: "Back to Home page",
      }
    },
    de: {
      translation: {
        "hello": "Hallo",
        "iam": "Ich bin Ming",
        nav: {
          "home": "Home",
          "about": "Über mich",
          "references": "Referenzen",
          "prices": "Preise",
          "contact": "Kontakt",
        },
        intro: {
          "did-you-always-want": "Wolltest du schon immer mal",
          "learn-chinese": "Chinesisch lernen?",
          "third-paragraph": "Oder möchtest du deine Sprachkenntnisse verbessern und Business-Chinesisch oder gar Kalligrafie lernen?",
          "fourth-paragraph": 'dann bist du hier <1 className="whitespace-nowrap">genau richtig!</1>'
        },
        about :{
          place: {
            title: "Teaching place",
            value: "Online"
          },
          languages: {
            title: "Languages",
            value: "Chinese, English, German"
          },
          text1: "Als leidenschaftliche Chinesisch-Lehrerin mit über 10 Jahren Erfahrung biete ich massgeschneiderten Online-Chinesischunterricht für alle Niveaus und Altersgruppen online an.",
          text2: 'Und dies zu <1>super Preisen</1>!',
          center: "Im Zentrum meines Unterrichts stehst du!",
          text3:    `Deine Bedürfnisse und Wünsche sollen den Unterricht formen.
          Ich helfe dir dabei, einen massgeschneiderten Unterrichtsplan gemäss deinen Zielen und Vorstellungen zu erstellen.
          
          Mein Ziel ist es, eine dynamische und interaktive Lernumgebung zu schaffen, die das Lernen lebendig, interessant und effizient macht.`,
 
        },
        students: {
          title: "Was sagen meine Schüler?",
        },
        onlineMaterial: {
          text1: "Folge meinem Youtube-Channel und profitiere von meinen kostenlosen Online-Materialien"
        },
        defaultMessage: "Ich möchte eine Offerte für personalisierten Chinesisch-Unterricht.",
        footer: {
          lookingForward: "Ich freue mich über Ihre Nachricht",
          yourName: "Ihr Name",
          pleaseEnterYourName: "Bitte geben Sie einen gültigen Namen ein",
          yourEmail: "Ihre Email-Adresse",
          pleaseEnterYourEmail: "Bitte geben Sie eine gültige Email-Adresse ein",
          yourMessage: "Ihre Nachricht",
          pleaseEnterYourMessage: "Bitte geben Sie eine gültige Nachricht ein",
        },
        message: {
          success: "Ihre Nachricht wurde erfolgreich gesendet. Besten Dank!",
        },
        contact: {
          send: "Senden",
          direct: "Oder direkt anfragen per <1>E-Mail</1>.",
        },
        privacy: {
          title: "Datenschutz",
          content: "Wir speichern keine Daten. Die einzigen Daten, die wir von Ihnen einsehen, sind diejenigen Informationen, die Sie via Kontaktformular preisgeben, also Ihren Namen, Email Adresse und Ihre Nachricht.",
        },
        impressum: {
          title: "Impressum",
          responsible: "Verantwortlich",
        },
        backToStart: "Zurück zur Startseite",

      }
    }
    }
  });