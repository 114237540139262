import RaniaAvatar from "./rania.png"
import RaffaelAvatar from "./raffael.png"
import OlgaAvatar from "./olga.png"
import EnautAvatar from "./enaut.png"
import TeddyAvatar from "./teddy.png"

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const testimonials: Testimonial[] = [
  {
    avatar: EnautAvatar,
    "name": "Eñaut Marti",
    "title": "CEO von MusicLAB World",
    "text": "Ming ist eine außergewöhnliche Chinesischlehrerin, deren Leidenschaft für die Sprache ansteckend ist. Ihre innovativen Lehrmethoden machten das Erlernen von Mandarin für mich angenehm und effektiv. Ihre Geduld und Ermutigung haben mein Vertrauen in das Sprechen und Verstehen von Chinesisch stark gefördert."
  },
  {
    avatar: RaniaAvatar,
    "name": "Ourania Katavouta",
    "title": "Dozentin am Department für Griechische Studien an der Shanghai International Studies University",
    "text": "Als Studentin von Ming kann ich mit Zuversicht sagen, dass ihr Unterrichtsstil sowohl einnehmend als auch sehr informativ ist. Sie verbindet traditionelle und moderne Lernmethoden nahtlos, sodass jede Lektion eine einzigartige und bereichernde Erfahrung ist. Ihr tiefes Wissen über die chinesische Kultur fügt ihren Lektionen eine unschätzbare Dimension hinzu."
  },
  {
    avatar: RaffaelAvatar,
    "name": "Rafael Tschui",
    "title": "Mitbegründer von octanis.ch",
    "text": "Chinesisch mit Ming zu lernen war eine transformative Erfahrung. Ihr personalisierter Ansatz stellte sicher, dass die Lektionen auf mein Lerntempo und meine Interessen zugeschnitten waren. Ihre Hingabe zum Unterrichten und ihre Fähigkeit, komplexe Konzepte leicht verständlich zu machen, ist wirklich bemerkenswert."
  },
  {
    avatar: OlgaAvatar,
    "name": "Olga Bykova",
    "title": "Lehrerin an der Montessori-Schule Shanghai",
    "text": "Mings Ansatz zum Chinesischunterricht ist sowohl gründlich als auch inspirierend. Ihre Lektionen sind eine perfekte Mischung aus herausfordernd und unterstützend, sie drängen die Schüler zum Erfolg, während sie die notwendige Anleitung bieten. Mings tiefes Verständnis sowohl der Sprache als auch der effektiven Lehre macht sie zu einer herausragenden Pädagogin."
  },
  {
    avatar: TeddyAvatar,
    "name": "Teddy Atuluku",
    "title": "Kreativer Strategist bei Void Studio, Nigeria",
    "text": "Ming's Unterricht war ein Wendepunkt für mich. Ihre fesselnden Lektionen, die an mein Tempo und meine Interessen angepasst sind, entfachten eine Leidenschaft für die Sprache, die ich nie gekannt habe. Unter ihrer Anleitung habe ich meine Mandarin-Fähigkeiten nicht nur dramatisch verbessert, sondern auch eine tiefere Wertschätzung für die chinesische Kultur gewonnen. Mings Hingabe zum Erfolg ihrer Schüler ist in jeder Lektion offensichtlich. Sie ist nicht nur eine Lehrerin; sie ist eine Mentorin, die Vertrauen und Wachstum inspiriert."
  }
]

const testimonialsEN: Testimonial[] = [
  {
    name: "Eñaut Marti",
    title: "CEO of MusicLAB World",
    avatar: EnautAvatar,
    text: `Ming is an exceptional Chinese teacher whose passion for the language is contagious.
      Her innovative teaching methods made learning Mandarin enjoyable and effective for me.
      Her patience and encouragement greatly boosted my confidence in speaking and understanding Chinese.`
  },
  {
    name: "Ourania Katavouta",
    title: "Lecturer at the Department of Greek Studies at the Shanghai International Studies University",
    avatar: RaniaAvatar,
    text: "As a student of Ming, I can confidently say that her teaching style is both engaging and highly informative. She blends traditional and modern learning techniques seamlessly, making each lesson a unique and enriching experience. Her deep knowledge of Chinese culture adds an invaluable dimension to her lessons."
  },
  {
    name: "Rafael Tschui",
    title: "Co-founder at octanis.ch",
    avatar: RaffaelAvatar,
    text: "Learning Chinese with Ming has been a transformative experience. Her personalized approach ensured that the lessons were tailored to my learning pace and interests. Her dedication to teaching and her ability to make complex concepts easy to understand is truly remarkable."
  },
  {
    name: "Olga Bykova",
    title: "Teacher at Montessori School of Shanghai",
    avatar: OlgaAvatar,
    text: "Ming's approach to teaching Chinese is both thorough and inspiring. Her lessons are a perfect blend of challenging and supportive, pushing students to excel while offering the guidance they need. Ming's deep understanding of both the language and how to teach it effectively makes her an outstanding educator."
  },
  {
    name: "Teddy Atuluku",
    title: "Creative Strategist at Void Studio, Nigeria",
    avatar: TeddyAvatar,
    text: "Ming's lessons were a turning point for me. Her engaging lessons, tailored to my pace and interests, ignited a passion for the language I never knew I had. Under her guidance, I not only dramatically improved my Mandarin skills but also gained a deeper appreciation for Chinese culture. Ming's dedication to her students' success is evident in every lesson. She is not just a teacher; she is a mentor who inspires confidence and growth."
  }
]

const Testimonials = () => {
  const { t, i18n } = useTranslation()

  i18n.addResourceBundle("en", "students", {
    testimonials: testimonialsEN
  })

  i18n.addResourceBundle("de", "students", {
    testimonials: testimonials
  })

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      setIsMobile(window.innerWidth < 768)
    };

    onScroll();
    window.addEventListener('resize', onScroll, { passive: true });

    return () => {
      window.removeEventListener('resize', onScroll)
    };
  }, []);

  type StudentProps = {
    testimonial: Testimonial,
    index: number,
  }
  const Student = ({ testimonial, index }: StudentProps) => {
    return (
      <div key={index} className="w-full flex flex-col items-center">
        <img className="shadow-md rounded-full w-56 h-56 object-contain" src={testimonial.avatar} alt={testimonial.name} />

        <div className="flex flex-col text-center px-8">
          <h4 className="font-bold mt-4">{testimonial.name}</h4>
          <p className="text-sm italic h-12">
          { t(`students:testimonials.${index}.title`) }
          </p>
          <p className="py-4 whitespace-pre-line text-left">
            { t(`students:testimonials.${index}.text`) }
          </p>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        centerMode={!isMobile}
      >
        {
          testimonials.map((testimonial, index) => (
            <Student testimonial={testimonial} index={index} />
          ))
        }
      </Carousel>
    </div>
  )
}

export default Testimonials