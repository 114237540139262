import React, { PropsWithChildren } from 'react'

type ExpressionProps = PropsWithChildren<{
  className?: string
}>

const Expression = (props: ExpressionProps) => (
  <h4 className={`${props.className ?? ""} px-4 py-4 mx-auto 
    text-amber-400 md:text-3xl text-2xl uppercase font-lexend font-extralight`}>
    {props.children}
  </h4>
)


export default Expression