import { useTranslation } from "react-i18next"

const lngs = {
  de: { nativeName: 'Deutsch' },
  en: { nativeName: 'English' },
}
const LanguageSwitcher = () => {
  const { i18n } = useTranslation()

  const handleOnClick = (lng: string) => {
    i18n.changeLanguage(lng)
    // refresh window
    window.location.reload();
  }

  return (
    <div className="flex gap-2 pr-2 pt-2 text-sm">
      {
        Object.keys(lngs).map((lng) => (
          <button className={`${i18n.resolvedLanguage === lng ? "text-neutral-500" : "underline"} hover:no-underline`} key={lng} onClick={() => handleOnClick(lng)} disabled={i18n.resolvedLanguage === lng}>
            {lngs[lng as keyof typeof lngs].nativeName}
          </button>
        ))
      }
    </div>
  )
}

export default LanguageSwitcher