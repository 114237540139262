import "./Index.css"

import { useEffect, useState } from "react"
import Testimonials from "../components/Testimonials/Testimonials"
import Paragraph from "../components/Paragraph"
import { ReactComponent as DownArrow } from "../components/images/down-arrow-svgrepo-com.svg"
import { ReactComponent as LocationIcon } from "../components/images/location-sign-svgrepo-com.svg"
import { ReactComponent as LanguageIcon } from "../components/images/language-svgrepo-com.svg"
import Nav from "../components/Nav"
import Expression from "../components/Expression"
import Footer from "../components/Footer"
import Subtitle from "../components/Subtitle"
import AboutPropertyText from "../components/AboutPropertyText"

import { Trans, useTranslation } from "react-i18next"
import LanguageSwitcher from "../components/LanguageSwitcher"

const handleScrollDown = () => {
  window.scrollTo({ top: window.innerHeight, behavior: "smooth" })
}

/*
const handleScrollTo = (id: string) => {
  const element = document.getElementById(id)
  element?.scrollIntoView({ behavior: "smooth" })
}*/



const Index = () => {
  const { t } = useTranslation();
  const [showNav, setShowNav] = useState(false);
  //const [defaultMessage, setDefaultMessage] = useState("");

  /*
  const handlePricesRequest = (message: string) => {
    let messageField = document.getElementById("message")
    setDefaultMessage(message)
    messageField?.scrollIntoView({ behavior: "smooth" })
  }
    */

  useEffect(() => {
    const onScroll = () => {
      setShowNav(window.scrollY > window.innerHeight - 100);
    };

    window.addEventListener('scroll', onScroll, { passive: true });
    window.addEventListener('resize', onScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', onScroll)
      window.removeEventListener('resize', onScroll)
    };
  }, []);

  return (
    <div>

      {
        /* Nav */
        <Nav showNav={showNav} />
      }

      <button id="home" type="button" className="block w-screen h-screen relative cursor-pointer" onClick={handleScrollDown}>
        <img className="w-screen h-screen object-cover" src={process.env.PUBLIC_URL + "/Ming-header.png"} alt="Ming" />
        <div className="absolute bottom-0 w-full flex justify-center">
          <DownArrow alt="Down Arrow" className="text-white animate-bounce w-24 pb-8" />
        </div>
      </button>

      <div className="absolute right-0 top-0">
        <LanguageSwitcher />
      </div>

      <div className="pointer-events-none absolute top-0 left-0 items-center px-10 text-xl md:text-6xl 
        flex flex-wrap justify-between w-full md:h-screen">
        <div className="font-lexend font-extralight text-center py-8 md:pl-16">
          {t("hello")}<br />
          你好
        </div>

        <h2 className="font-lexend font-extralight text-center py-8">
          {t("iam")}!<br />
          我叫明
        </h2>
      </div>

      {
        /* Intro Text */
        <div className="text-base pt-32 pb-8 px-4 text-center max-w-md mx-auto">
          <div className="mx-auto">
            <p className="text-3xl py-4 leading-relaxed">
              {t("intro.did-you-always-want")}
            </p>
            <p className="text-6xl text-amber-400 text-center">
              <span className="text-[8rem] font-extralight opacity-25 -mb-24 block">学中文</span>
              <br />
              <span>
                {t("intro.learn-chinese")}
              </span>
            </p>

            <p className="text-xl pt-4 leading-relaxed">
              {t("intro.third-paragraph")}
              <br />
            </p>

            <Expression className="mt-8 overflow-hidden">
              <Trans i18nKey={"intro.fourth-paragraph"}>
                dann bist du hier <span className="whitespace-nowrap">genau richtig!</span>
              </Trans>
            </Expression>
          </div>

        </div>
      }

      <div id="about" className="md:rounded-md scroll-mt-16 md:scroll-mt-24 
        mx-auto container 
        shadow-md
        bg-gradient-to-br from-stone-500 to-stone-600 my-4 overflow-hidden">

        <div className="flex flex-col md:flex-row justify-center items-center">

          <div>
            <img className="h-full object-cover" src={process.env.PUBLIC_URL + "/ming-autumn.jpg"} alt="Ming" />
          </div>

          <div className="text-white py-8 px-4">

            <div className="flex flex-wrap justify-around text-left p-4">
              <AboutPropertyText Icon={LocationIcon} iconAlt="Location" title={t("about.place.title")} description={t("about.place.value")} />
              <AboutPropertyText Icon={LanguageIcon} iconAlt="Language" title={t("about.languages.title")} description={t("about.languages.value")} />
            </div>

            <p className="text-xl pb-4 my-4 px-4  drop-shadow-md">
              {t("about.text1")}
            </p>
              {
                /*
                            <Paragraph>

                              <Trans i18nKey={"about.text2"}>
                Und dies zu <Link to="#" onClick={e => handleScrollTo("preise")} className="hover:text-neutral-300 border-b-4 pb-1 border-amber-400">super Preisen</Link>!
              </Trans>            </Paragraph>

                */
              }

          </div>
        </div>
      </div>

      <div className="max-w-2xl mx-auto my-16">
        <div>
          <Expression className="my-8">
            {t("about.center")}
          </Expression>
          <Paragraph className="whitespace-pre-line">
            {t("about.text3")}
          </Paragraph>
        </div>
      </div>

      {
        /* Was sagen meine Schüler? */
        <div className="bg-amber-400 py-8 scroll-mt-12" id="referenzen">
          <Subtitle className="text-center px-4 pb-12 text-white">
            { t("students.title") }
          </Subtitle>
          <Testimonials />
        </div>
      }

      {
        // Online material
        <div className="md:px-24">
          <Subtitle className="text-center pb-8 pt-16 text-amber-400">
            Online-Material
          </Subtitle>
          <p className="md:w-1/2 mx-auto pb-8 text-center">
          { t("onlineMaterial.text1")}
          </p>
          <div className="flex md:flex-nowrap flex-wrap">
            <div className="basis-full md:basis-1/3 p-4">
              <iframe className="w-full aspect-[16/9]" src="https://www.youtube.com/embed/AIoZd9dFBsc?si=_aDP40ebyyY5_qVW" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>
            <div className="basis-full md:basis-1/3 p-4">
              <iframe className="w-full aspect-[16/9]" src="https://www.youtube.com/embed/g9RdvX6gC2M?si=xrdtMGEr9upB02yb" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>
            <div className="basis-full md:basis-1/3 p-4">
              <iframe className="w-full aspect-[16/9]" src="https://www.youtube.com/embed/Za9HYNGqOEg?si=Ii7gcWzT_POICzGl" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>
          </div>
        </div>
      }

      {
        // <Preise onRequest={handlePricesRequest} />
      }

      <Footer defaultMessage={t("defaultMessage")} id="kontakt" className="scroll-mt-24" />

    </div>
  )
}

export default Index