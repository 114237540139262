
const Input = (props: {
  type: string,
  id: string,
  name?: string,
  placeholder?: string,
  register?: any,
  errors?: any,
}) => (
  <div>
    {
      props.register ?
        <input className="p-4 mt-4 
    border border-neutral-100
    shadow-md
    focus:outline-none focus:ring-1 focus:ring-amber-400
    w-full"
          {...props.register}
          type={props.type} id={props.id} placeholder={props.placeholder} />
        :
        <input className="p-4 mt-4 
    border border-neutral-100
    shadow-md
    focus:outline-none focus:ring-1 focus:ring-amber-400
    w-full"
          type={props.type} id={props.id} name={props.name} placeholder={props.placeholder} />
    }

    {
      /* Error message */
      props.errors &&  
      <p className="text-red-500 text-sm mt-4">
        {props.errors.message}
      </p>
    }
  </div>
)
export default Input