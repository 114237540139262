import { PropsWithChildren } from "react"

type SubtitleProps = PropsWithChildren<{
  className?: string,
  lang?: string
}>

const Subtitle = (props: SubtitleProps) => (
  <h3 className={`${props.className ?? ""} text-2xl font-bold uppercase font-lexend`} lang="de">
    {props.children}
  </h3>
)

export default Subtitle