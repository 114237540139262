type TextareaProps = {
  id: string,
  name?: string,
  message: string,
  register?: any,
  errors?: any
}

const Textarea = (props: TextareaProps) => {
  return (
    <div>
      {
        props.register ?
          <textarea
            className="min-h-[10rem] p-4 mt-4 
            w-full
        focus:outline-none focus:ring-1 focus:ring-amber-400 
        shadow-md border border-neutral-100"
            {...props.register}
            id={props.id}
            placeholder={props.message} />
          :
          <textarea
            className="min-h-[10rem] p-4 my-4 
      focus:outline-none focus:ring-1 focus:ring-amber-400 
      shadow-md border border-neutral-100"
            id={props.id}
            name={props.name}
            placeholder={props.message} />
      }

      {
        /* Error message */
        props.errors &&
        <p className="text-red-500 text-sm mt-4">
          {props.errors.message}
        </p>
      } 
    </div>
  )
}

export default Textarea