import { PropsWithChildren } from "react"

type Props = PropsWithChildren<{
  className?: string
}>

const Paragraph : React.FC<Props> = ({ className, children}) => {
  return (
    <p className={`text-xl px-4 ${className ?? ""}`.trim()}>
      {children}
      </p>
  )
}

export default Paragraph