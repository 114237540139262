import { createBrowserRouter } from "react-router-dom";
import Index from "../pages/Index";
import Impressum from "../pages/Impressum";
import Datenschutz from "../pages/Datenschutz";

const Router = createBrowserRouter([
  {
    path: "/",
    element: <Index />,
  },
  {
    path: "/impressum",
    element: <Impressum />, 
  },
  {
    path: "/datenschutz",
    element: <Datenschutz />, 
  },
]);

export default Router