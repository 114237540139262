import { useTranslation } from 'react-i18next'
import Title from '../components/Title'
import { Link } from 'react-router-dom'

const Datenschutz = () => {
  const { t } = useTranslation()
  return (
    <div className='container mx-auto mt-24'>
      <Title text={t("privacy.title")} />
      <div>
        { t("privacy.content") }
      </div>
      <Link to="/" className='underline hover:no-underline mt-4'>{t("backToStart")}</Link>
    </div>  )
}

export default Datenschutz